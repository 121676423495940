import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters.js';
import mutations from './mutations.js';
import actions from './actions.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state() {
    return {
      data:[
        {
          carName: 'Škoda Superb - Laurin & Klement',
          date: '01/18',
          motor: '140 kW',
          type: 'diesel',
          liter: '5.5l/100km',
          crew: '5',
          img: 'IMG_1',
          maxKm:{
            firstLevel: '250km',
            secondLevel: '250km',
            thirdLevel: '200km',
            fourthLevel: '200km',
          },
          prize:{
            firstLevel: '65€',
            secondLevel: '60€',
            thirdLevel: '50€',
            fourthLevel: '45€',
            moneyInFront: '400€'
          },
        },
        {
          carName: 'Škoda Superb - Laurin & Klement',
          date: '02/18',
          motor: '110 kW',
          type: 'diesel',
          liter: '5.5l/100km',
          crew: '5',
          img: 'IMG_2',
          maxKm:{
            firstLevel: '250km',
            secondLevel: '250km',
            thirdLevel: '200km',
            fourthLevel: '200km',
          },
          prize:{
            firstLevel: '65€',
            secondLevel: '60€',
            thirdLevel: '50€',
            fourthLevel: '45€',
            moneyInFront: '400€'
          },
        },
        {
          carName: 'Škoda Yeti',
          date: '01/18',
          motor: '92 kW',
          type: 'benzín',
          liter: '5.8l/100km',
          crew: '5',
          img: 'IMG_3',
          maxKm:{
            firstLevel: '300km',
            secondLevel: '300km',
            thirdLevel: '300km',
            fourthLevel: '300km',
          },
          prize:{
            firstLevel: '45€',
            secondLevel: '40€',
            thirdLevel: '35€',
            fourthLevel: '33€',
            moneyInFront: '300€'
          },
        },
        {
          carName: 'Seat Leon ST ',
          date: '01/19',
          motor: '110 kW',
          type: 'diesel',
          liter: '5l/100km',
          crew: '5',
          img: 'IMG_8',
          maxKm:{
            firstLevel: '250km',
            secondLevel: '250km',
            thirdLevel: '250km',
            fourthLevel: '200km',
          },
          prize:{
            firstLevel: '55€',
            secondLevel: '50€',
            thirdLevel: '40€',
            fourthLevel: '33€',
            moneyInFront: '300€'
          },
        },
        {
          carName: 'Seat Leon',
          date: '01/22',
          motor: '110 kW',
          type: 'diesel',
          liter: '5l/100km',
          crew: '5',
          img: 'IMG_1',
          maxKm:{
            firstLevel: '250km',
            secondLevel: '250km',
            thirdLevel: '250km',
            fourthLevel: '200km',
          },
          prize:{
            firstLevel: '60€',
            secondLevel: '55€',
            thirdLevel: '48€',
            fourthLevel: '38€',
            moneyInFront: '400€'
          },
        },
        {
          carName: 'Renault Trafic Combi',
          date: '05/24',
          motor: '110 kW',
          type: 'diesel',
          liter: '7l/100km',
          crew: '8',
          img: 'IMG_1',
          maxKm:{
            firstLevel: '300km',
            secondLevel: '300km',
            thirdLevel: '300km',
            fourthLevel: '300km',
          },
          prize:{
            firstLevel: '85€',
            secondLevel: '75€',
            thirdLevel: '60€',
            fourthLevel: '50€',
            moneyInFront: '400€'
          },
        },
      ]
    };
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
  }
})
