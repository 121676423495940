<template>
  <div class="about">
    <h3>
        O nás
    </h3>
    <p>
        Na prenájom zabezpečujeme osobné a úžitkové vozidlá rozdelené do kategórií od ekonomickej až po luxusný manažérsky štandard.
        V ponuke sú vozidlá značiek Volkswagen, Audi, Škoda.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.about{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3{
        font-family: 'UniviaPro-Bold';
        font-size: 46px;
        color: #001448;
        margin-bottom: 10px;
        border-bottom: 1px solid #DEDEDE;
        padding-bottom: 5px;
        margin-bottom: 25px;
    }
    p{
        text-align: center;
        width: 900px;
        font-size: 26px;
        color: #0014489f;
    }
}
@media screen and (max-width: 1000px) {
.about{
    h3{
        font-family: 'UniviaPro-Bold';
        font-size: 30px;
        color: #001448;
        margin-bottom: 10px;
    }
    p{
        text-align: center;
        width: 500px;
        font-size: 20px;
        color: #0014489f;
    }
}
}
@media screen and (max-width: 600px) {
.about{
    p{
        width: 340px !important;
        font-size: 16px !important;
    }
}
}
@media screen and (max-width: 400px) {
.about{
    p{
        width: 300px !important;
        font-size: 16px !important;
    }
}
}
</style>