import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'
import CarView from '../views/CarView.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/car-view/:id',
    name: 'CarView',
    component: CarView,
    props: true,
    beforeEnter: (to, from, next) => {
      const id = parseInt(to.params.id);
      if (id === 1 || id === 2 || id === 3 || id === 4 || id === 5 || id === 6) {
        next();
      } else {
        next({ name: 'PageNotFound' });
      }
    }
  },
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
