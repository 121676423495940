<template>
  <div class="footer-view">
    <h3>Kontakt</h3>
    <div class="footer-view-items">
        <div class="footer-view-item">
            <p>MP Cars Humenné s.r.o</p>
            <div class="p-numbers">
            <p style="margin-right: 5px; margin-bottom: 0;">Telefónne čísla:</p>
                <div style="display:flex; flex-direction: column;">
                    <a href="tel:+421908333103">0908 333 103</a>
                    <a href="tel:+421918827332">0918 827 332</a>
                </div>
            </div>
            <p>Email: mpcars@mpcars.sk</p>
            <p>Kamenica nad Cirochou, 067 83</p>
            <p>Vihorlatská 98/8</p>
            <p>Slovensko</p>
        </div>
        <div class="footer-view-item">
            <p><a href="https://goo.gl/maps/qXw7eAjf8hKVN7S58">Naviguj ma.</a></p>
            <div class="footer-view-item-1">
                <p>Socialne siete:</p>
                <a href="https://www.facebook.com/profile.php?id=100090766520994"><img src="@/assets/icons/image-fb.png" alt="facebook"></a>
                <img src="@/assets/icons/image-inst.png" alt="instagram">
                <img src="@/assets/icons/image-what.png" alt="whatsup">
            </div>

        </div>
    </div>
    <p class="last">
        © 2023 MP cars. Všetky práva vyhradené.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer-view{
    background-color: black;
    .p-numbers{
        display:flex;
        align-items: center;
    }
    h3{
        padding-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        font-family: 'UniviaPro-Bold';
        font-size: 46px;
        color: white;
        margin-bottom: 10px;
    }
    .last{
        color: white;
        font-size: 20px;
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 0;
    }
    .footer-view-items{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .footer-view-item{
            margin-right: 20px;
            .footer-view-item-1{
                display: flex;
                align-items: center;
                p{
                    margin-right: 3px;
                }
                img{
                    margin-right: 4px;
                    width: 25px;
                    filter: saturate(0%);
                }
                img:hover {
                    filter: saturate(300%);
                    cursor: pointer;
                }
            }
            p{
                color: white;
                margin-bottom: 5px;
            }
        }
    }
}
@media screen and (max-width: 600px) {
h3{
    font-size: 30px !important;
}
p{
    font-size: 12px !important;
}
.p-numbers{
    justify-content: center;
}
.footer-view-items{
    flex-direction: column !important;
    text-align: center;
    .footer-view-item-1{
        justify-content: center;
    }
}
}
</style>