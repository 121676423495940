<template>
  <div class="park-view">
    <h3>
        Vozový park
    </h3>
    <div class="items">
    <div class="park-view-item" v-for="(car, index) in data" :key="index" @click="goToCarView(index+1)">
        <h4>{{car.carName}}</h4>
        <div class="park-view-item-inside">
            <img class="img-front" :src="iconPath(index)" alt="img">
            <div class="right-bar">
                <div class="right-bar-item">
                    <img src="@/assets/icons/image-date.png" alt="">
                    <p>{{ car.date }}</p>
                </div>
                <div class="right-bar-item">
                    <img src="@/assets/icons/image-motor.png" alt="">
                    <p>{{ car.motor }}</p>
                </div>
                <div class="right-bar-item">
                    <img src="@/assets/icons/image-gas.png" alt="">
                    <p>{{ car.type }}</p>
                </div>
                <div class="right-bar-item">
                    <img src="@/assets/icons/image-liter.png" alt="">
                    <p>{{ car.liter }}</p>
                </div>
                <div class="right-bar-item">
                    <img src="@/assets/icons/image-people.png" alt="">
                    <p>{{ car.crew }}</p>
                </div>
            </div>
        </div>
    </div>
    </div>

  </div>
</template>

<script>
export default {
    name: 'ParkView',
    props: {
        data: Array,
        img: String
    },
    methods: {
    goToCarView(id) {
      this.$router.push({ name: 'CarView', params: { id: id } })
    },
    iconPath (idx) {
      return require(`@/assets/front-cars/${this.data[idx].img}.jpg`)
    },
  },
}
</script>

<style lang="scss" scoped>
.park-view{
    margin-top: 40px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 50px;
    h3{
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'UniviaPro-Bold';
        font-size: 46px;
        color: #001448;
        border-bottom: 1px solid #DEDEDE;
        width: 300px;
        margin: 0 auto 10px auto;
        padding-bottom: 5px;
        margin-bottom: 25px;
    }
    .items{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .park-view-item{
        width: 580px;
        margin-bottom: 20px;
        h4{
            text-align: center;
            font-family: 'UniviaPro-Bold';
            font-size: 26px;
            color: #001448;
            margin-bottom: 15px;
            cursor: pointer;
        }
        .park-view-item-inside{
            border-radius: 10px;
            background-color: #EFEFEF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .img-front{
                border-radius: 10px 0 0 10px;
                max-width: 100%;
                width: 462px;
            }
            .right-bar{
                margin-right: 20px;
                .right-bar-item{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 8px;
                    img{
                        border-radius: 0;
                        margin-bottom: 3px;
                    }
                    p{
                        margin: 0 !important;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
.park-view{
  margin-top: 0 !important;
  h3{
    font-size: 30px !important;
    margin-bottom: 15px;
  }
  .park-view-item{
    h4{
        text-align: center;
        margin-left: 0 !important;
        font-size: 18px !important;
    }
  }
}
}
.right-bar{
    margin-right: 30px !important;
}
.right-bar-item{
    p{
        font-size: 12px;
    }
}
@media screen and (max-width: 700px) {
    .park-view-item{
        width: 520px !important;
    }
    .park-view-item-inside{
        .img-front{
            width: 400px !important;
        }
    }
}
@media screen and (max-width: 600px) {
    .park-view{
        margin-bottom: 20px !important;
    }
    .park-view-item{
        width: 400px !important;
    }
    .park-view-item-inside{
        display: block !important;
        .img-front{
            border-radius: 10px 10px 0 0 !important;
        }
        .right-bar{
            display: flex;
            justify-content: space-between;
            margin-left: 20px;
            margin-top: 10px;
        }
    }
}
</style>