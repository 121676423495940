<template>
  <div>
  <div id="home" class="home">
      <div class="home-header">
        <h1>
          Spoľahlivá požičovňa aút v Humennom
        </h1>
        <p>
          Potrebujete požičať auto, neváhajte nás kontaktovať. Nemusíte sa obávať, pretože naša autopožičovňa je tu pre vás non-stop. Stačí nás kontaktovať a radi vám pomôžeme s výberom auta, ktoré najlepšie vyhovuje vašim potrebám.
        </p>
      </div>
      <img src="@/assets/icons/image-car-right.png" alt="">
    </div>
    <div class="banner">
      <img src="@/assets/icons/vw.png" alt="logo">
      <img src="@/assets/icons/skoda.png" alt="logo">
      <img src="@/assets/icons/audi.png" alt="logo">
    </div>
    <AboutView id="about"/>
    <ParkView :data="data" id="carpark"/>
  </div>

</template>

<script>
import ParkView from '@/views/ParkView.vue'
import AboutView from '@/views/AboutView.vue'

export default {
  name: 'Home',
  data () {
    return {
      data:[
        {
          carName: 'Škoda Superb - Laurin & Klement',
          date: '06/18',
          motor: '140 kW',
          type: 'diesel',
          liter: '6.0l/100km',
          crew: '5',
          img: 'IMG_1'
        },
        {
          carName: 'Škoda Superb - Laurin & Klement',
          date: '04/18',
          motor: '110 kW',
          type: 'diesel',
          liter: '5.5l/100km',
          crew: '5',
          img: 'IMG_2'
        },
        {
          carName: 'Škoda Yeti',
          date: '01/18',
          motor: '92 kW',
          type: 'benzín',
          liter: '5.8l/100km',
          crew: '5',
          img: 'IMG_3'
        },
        {
          carName: 'Seat Leon ST ',
          date: '01/19',
          motor: '110 kW',
          type: 'diesel',
          liter: '5l/100km',
          crew: '5',
          img: 'IMG_4'
        },
        {
          carName: 'Seat Leon ',
          date: '01/22',
          motor: '110 kW',
          type: 'diesel',
          liter: '5l/100km',
          crew: '5',
          img: 'IMG_5'
        },
        {
          carName: 'Renault Trafic Combi',
          date: '05/24',
          motor: '110 kW',
          type: 'diesel',
          liter: '7l/100km',
          crew: '8',
          img: 'IMG_6'
        }
      ]
    }
  },
  components: {ParkView, AboutView},
}
</script>
<style lang="scss" scoped>
  .home{
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    .home-header{
      margin-top: 130px;
      margin-left: 100px;
      width: 500px;
      h1{
        font-size: 64px;
        font-weight: 700;
        font-family: 'UniviaPro-Bold';
        color: #001448;
        margin-bottom: 10px;
      }
      p{
        font-size: 20px;
        color: #0014489f;
      }
    }
    img{
      position: absolute;
      right: 0;
      top: 150px;
      z-index: -1;
    }
  }
  .banner{
    margin-top: 150px;
    margin-bottom: 50px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;
    img {
      filter: saturate(0%);
    }
    img:hover {
        filter: saturate(300%);
        cursor: pointer;
    }
    img:first-child{
      margin-left: 5%;
    }
    img:last-child{
      margin-right: 5%;
    }

  }
@media screen and (max-width: 1472px) {
.home{
  img{
    width: 50%;
  }
}
}
@media screen and (max-width: 1215px) {
  .home-header{
    width: 400px !important;
      h1{
        font-size: 44px !important;
      }
      p{
        font-size: 18px !important;
      }
    }
}
@media screen and (max-width: 1000px) {
.home{
  justify-content: center;
  .home-header{
    text-align: center;
    margin-top: 50px;
    margin-left: 0 !important;
  }
  img{
    display: none !important;
  }
}
.banner{
  margin-top: 30px !important;
  margin-bottom: 20px !important;
  img{
    width: 75px;
  }
}
}
@media screen and (max-width: 600px) {
  .home-header{
    width: 400px !important;
    margin-top: 25px !important;
    margin-left: 25px !important;
      h1{
        font-size: 35px !important;
      }
      p{
        font-size: 16px !important;
      }
    }
}
</style>
